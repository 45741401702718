import { FormEventHandler, useEffect } from 'react'
import {
  DeepPartial,
  SubmitHandler,
  UnpackNestedValue,
  UseFormMethods,
  useForm,
  useWatch,
} from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { camelCasifyProperties } from '@procsea/common/utils'

import { isErrorResponse } from 'src/action-creators'
import { EMAIL_REGEX } from 'src/constants/constants'
import { useAuth } from 'src/contexts/authContext'
import { LocaleParams } from 'src/types'
import { handleErrorResponse } from 'src/utils'
import { REQUIRED_MESSAGE, requiredRule } from 'src/utils/form/validationRules.utils'

export interface SellerSignupFormValue {
  address: string
  country: string
  companyName: string
  email: string
  firstName: string
  lastName: string
  locality: string
  password: string
  phoneFixed: string
  phoneMobile: string
  terms: boolean
  zipCode: string
}

const defaultValues: SellerSignupFormValue = {
  address: '',
  country: '',
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  locality: '',
  password: '',
  phoneFixed: '',
  phoneMobile: '',
  terms: false,
  zipCode: '',
}

interface UseSellerSignupFormReturn extends UseFormMethods<SellerSignupFormValue> {
  fields: UnpackNestedValue<DeepPartial<SellerSignupFormValue>>
  onSubmit: FormEventHandler<HTMLFormElement>
}

const AGREEMENT_MESSAGE = gettext(
  'You must agree to the general terms of use to be able to signup.'
)

export const useSellerSignupForm = ({
  companyUuid,
}: {
  companyUuid?: string
}): UseSellerSignupFormReturn => {
  const dispatch = useDispatch()
  const auth = useAuth()

  const { push } = useHistory()
  const { locale } = useParams<LocaleParams>()

  const form = useForm<SellerSignupFormValue>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const fields = useWatch<SellerSignupFormValue>({ control: form.control })

  useEffect(() => {
    form.register('email', {
      pattern: {
        message: gettext('Invalid email'),
        value: EMAIL_REGEX,
      },
      required: REQUIRED_MESSAGE,
    })
    form.register('firstName', requiredRule)
    form.register('lastName', requiredRule)
    form.register('password', {
      minLength: {
        message: gettext('This password is too short, it must contain at least 8 characters'),
        value: 8,
      },
      required: REQUIRED_MESSAGE,
    })
    form.register('phoneFixed', requiredRule)
    form.register('phoneMobile')
    form.register('terms', { required: AGREEMENT_MESSAGE })

    if (!companyUuid) {
      form.register('address', requiredRule)
      form.register('country', requiredRule)
      form.register('companyName', requiredRule)
      form.register('locality', requiredRule)
      form.register('zipCode', requiredRule)
    }

    return () => {
      form.reset()
    }
  }, [])

  const onValid: SubmitHandler<SellerSignupFormValue> = async value => {
    const response = await auth.registerSeller({
      ...value,
      company_uuid: companyUuid,
      terms: value.terms.toString(),
    })

    const body = camelCasifyProperties(await response.json())

    if (isErrorResponse(body)) {
      handleErrorResponse({ setError: form.setError, dispatch, formValue: value })(body)
    } else {
      push(`/${locale}/accounts/seller/done`)
    }
  }

  return { ...form, fields, onSubmit: form.handleSubmit(onValid) }
}
