import React from 'react'
import styled from 'styled-components'

import { useQueryParams } from '@procsea/common/hooks'
import { GlobalStyle, Stack, Text } from '@procsea/design-system'

import useDocumentTitle from 'src/hooks/useDocumentTitle'

import { AuthContainer, AuthMainCard } from '../common'
import AuthHeader from '../common/AuthHeader'
import Form from './Form'

const DEFAULT_BACKGROUND_COLOR = '#ffffff'

interface BackgroundProps {
  backgroundColor: string
}

const Background = styled.div<BackgroundProps>`
  background-color: ${props => props.backgroundColor};
  min-height: 100vh;
  padding: ${props => props.theme.spacings.large};
  text-align: center;
  width: 100vw;
`

const SellerSignup = () => {
  const queryParams = useQueryParams()

  useDocumentTitle(gettext('Supplier Sign Up'))

  const isEmbedded = queryParams.get('embedded') === 'true'
  const backgroungColor = queryParams.get('bg_color') || DEFAULT_BACKGROUND_COLOR
  const ctaColor = queryParams.get('cta_color')
  const companyUuid = queryParams.get('to')

  if (isEmbedded) {
    return (
      <Background backgroundColor={backgroungColor}>
        <Form companyUuid={companyUuid} customCtaColor={ctaColor} showSignInLink={false} />
      </Background>
    )
  }

  return (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />

      <AuthHeader data-e2e="buyer-signup-home-link" />

      <Stack spacing="xsmall">
        <Text size="xxxlarge" variant="secondary">
          {gettext('Create a supplier account')}
        </Text>

        <Text size="large" variant="secondary">
          {gettext('Expand your sales in a few clicks')}
        </Text>
      </Stack>

      <AuthMainCard shadow="middle" spacing="xlarge">
        <Form companyUuid={companyUuid} showSignInLink />
      </AuthMainCard>
    </AuthContainer>
  )
}

export default SellerSignup
